import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import dayjs from 'dayjs'

import Dot from '../../../components/Table/Dot'
import UserLevelList from '../../../components/Table/UserLevelList'
import {
  convertJoinDate,
  formatIdCard,
  validatePermission,
} from '../../../utils/lib'
import Table from './Table'
import TableView from './TableView'
import Upload from '../../../components/Upload/BasicUpload'
import Equipment from '../Equipment'
import { INVENTORY_SETTING, STOCK_PERMISSION } from '../../../constants/stock'

export const convertAreaToTable = ({ area }) => {
  const convertAreaList = area.map((item) => {
    return {
      department: _.get(item, 'department.department', 'ไม่ระบุ'),
      station: _.get(item, 'station.station', 'ไม่ระบุ'),
      shortName: _.get(item, 'department.initial', 'ไม่ระบุ'),
      default: _.get(item, 'default', false),
    }
  })
  return convertAreaList
}

const FullView = (props) => {
  const { initialValues, user, checkPermission, isPageLoading, NonAcademy } =
    props

  const {
    uuid,
    agentCode,
    prefix,
    firstName,
    lastName,
    prefixTH,
    firstNameTH,
    lastNameTH,
    position,
    level,
    jobTitles,
    area,
    costCenter,
    joinDate,
    status,
    reportTo,
    tel,
    email,
    deskNumber,
    birthOfDate,
    nationality,
    image,
    productType,
    permission,
  } = initialValues
  let idCardNo = _.get(initialValues, 'idCardNo', null)
  if (!_.isEmpty(_.get(initialValues, 'user.idCardNo', null))) {
    idCardNo = _.get(initialValues, 'user.idCardNo', null)
  }
  const canManagePermission = checkPermission || user.uuid === uuid

  const hasInventoryAdmin = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })

  const ContentField = (props) => {
    const { field, content, limitWidth } = props
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', pb: 1 }}>
        <Typography sx={{ alignSelf: 'center', width: '25%', ml: 3 }}>
          {field}
        </Typography>
        <Typography
          sx={{
            alignSelf: 'center',
            width: '75%',
            maxWidth: limitWidth ? limitWidth : 'unset',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {field === 'สถานะการทำงาน' && (
              <Dot sx={{ mt: 0, mr: 1 }} status={status} />
            )}
            {content !== '' ? content : 'ไม่ได้ระบุ'}
          </Box>
        </Typography>
      </Box>
    )
  }
  const LabelField = (props) => {
    const { text } = props
    return (
      <Typography sx={{ pb: 1.5 }} variant="h6">
        {text}
      </Typography>
    )
  }
  return (
    <Card sx={{ minWidth: 275, pt: 2 }}>
      {!isPageLoading && (
        <Box
          sx={{
            gap: 3,
            py: 3,
            px: 3,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {image !== '' && image !== null ? (
              <Upload
                value={image}
                isShowDescription={false}
                isPreview={true}
                style={{ width: 100, height: 100, borderRadius: '50%' }}
              />
            ) : (
              <img src="/logo/staff_profile_small.png" alt="StaffProfile" />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {canManagePermission ? (
                <>
                  <ContentField field="รหัสพนักงาน" content={agentCode} />
                </>
              ) : (
                <></>
              )}

              <ContentField
                field="Name"
                content={`${prefix} ${firstName}  ${lastName}`}
              />
              <ContentField
                field="ชื่อ - นามสกุล"
                content={`${prefixTH} ${firstNameTH}  ${lastNameTH}`}
              />
            </Box>
          </Box>

          {!NonAcademy && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <LabelField text="ข้อมูลการทำงาน" />
              <ContentField field="ประเภทของพนักงาน" content={position} />
              <ContentField
                field="ชื่อหน่วยงาน"
                content={_.get(initialValues, 'unitName', '-')}
              />
              <ContentField
                field="รหัสหน่วยงาน"
                content={_.get(initialValues, 'unitNumber', '-')}
              />
              <ContentField
                field="Level"
                content={_.get(level, 'level', 'ไม่ได้ระบุ')}
              />
              <ContentField
                field="Job Title"
                content={_.get(jobTitles, 'jobTitle', '')}
              />
              <ContentField
                field="Cost Center"
                content={_.get(costCenter, 'costCenter', 'ไม่ได้ระบุ')}
              />
              <Table
                headCells={[
                  {
                    id: 'department',
                    label: 'Department',
                    width: '80%',
                  },
                  {
                    id: 'shortName',
                    label: 'ชื่อย่อ',
                  },
                  {
                    id: 'station',
                    label: 'Station',
                    width: '20%',
                  },
                ]}
                list={convertAreaToTable({ area })}
              />
              <ContentField
                field="วันเริ่มงาน"
                content={
                  dayjs(joinDate || null).format(
                    window.__env__.REACT_APP_DATE_SHOW,
                  ) || 'ไม่ได้ระบุ'
                }
              />
              <ContentField
                field="อายุงาน"
                content={convertJoinDate(joinDate)}
              />
              <ContentField field="สถานะการทำงาน" content={status} />
              <ContentField
                field="Report to"
                content={`${_.get(reportTo, 'firstNameTH', '')} ${
                  _.get(reportTo, 'lastNameTH', '') || 'ไม่ได้ระบุ'
                }`}
              />
              <ContentField field="กลุ่มสิทธิ์การใช้งาน" content={permission} />
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <LabelField text="ข้อมูลติดต่อ" />
            <ContentField field="เบอร์โทรศัพท์" content={tel} />
            <ContentField field="อีเมล" content={email} />
            {!NonAcademy && (
              <ContentField field="เบอร์โต๊ะทำงาน" content={deskNumber} />
            )}
            {!checkPermission && user.uuid !== uuid && !NonAcademy && (
              <ContentField
                field="Cost Center"
                content={_.get(costCenter, 'costCenter', 'ไม่ได้ระบุ')}
              />
            )}
          </Box>

          {canManagePermission && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <LabelField text="ข้อมูลส่วนตัว" />

                <ContentField
                  field="วัน/เดือน/ปี เกิด"
                  content={
                    _.get(initialValues, 'birthOfDate', null) === null ||
                    _.get(initialValues, 'birthOfDate', null) === '-'
                      ? 'ไม่ได้ระบุ'
                      : dayjs(birthOfDate).format(
                          window.__env__.REACT_APP_DATE_SHOW,
                        )
                  }
                />
                {!NonAcademy && (
                  <ContentField field="สัญชาติ" content={nationality} />
                )}
                <ContentField
                  field="เลขบัตรประจำตัวประชาชน"
                  content={!_.isEmpty(idCardNo) ? formatIdCard(idCardNo) : '-'}
                />
                {}
                <ContentField
                  field="เพศ"
                  content={_.get(initialValues, 'user.gender.genderName', '-')}
                />
                <ContentField
                  field="รหัสไปรษณีย์"
                  content={_.get(initialValues, 'user.tambon.zipCode', '-')}
                />
                <ContentField
                  field="จังหวัด"
                  content={_.get(
                    initialValues,
                    'user.province.provinceName',
                    '-',
                  )}
                />
                <ContentField
                  field="อำเภอ/เขต"
                  content={_.get(
                    initialValues,
                    'user.amphure.amphureName',
                    '-',
                  )}
                />
                <ContentField
                  field="แขวง/ตำบล"
                  content={_.get(initialValues, 'user.tambon.tambonName', '-')}
                />
                <ContentField
                  field="รายละเอียดที่อยู่"
                  content={_.get(initialValues, 'user.address', '-')}
                />
              </Box>
            </>
          )}

          {(canManagePermission || hasInventoryAdmin) && !NonAcademy && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Equipment />
              </Box>
            </>
          )}

          {canManagePermission && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <LabelField text="ประสบการณ์การทำงาน" />
                {!NonAcademy && window.__env__.ENABLE_SKILL_MAPPING && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', pb: 1 }}>
                    <Typography
                      sx={{ alignSelf: 'center', width: '25%', ml: 3 }}
                    >
                      Skill Mapping
                    </Typography>
                    <Typography
                      sx={{
                        alignSelf: 'center',
                        width: '75%',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {productType.length > 0 ? (
                          <UserLevelList
                            levelList={productType.map((e) => e.productType)}
                          />
                        ) : (
                          'ไม่ได้ระบุ'
                        )}
                      </Box>
                    </Typography>
                  </Box>
                )}

                <TableView
                  initialValues={initialValues}
                  NonAcademy={NonAcademy}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Card>
  )
}
export default FullView
