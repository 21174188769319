import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import { useSelector, useDispatch } from 'react-redux'

import Select from '../../../../../components/Input/Select'
import Table from '../../../../../components/redux/Table/LearnerDetail'
import { Row } from '../../../../../components/Preview/Layout/Styled'
import * as events from './events'

export const ActionIcon = ({ item }) => {
  const learnerStatus = useSelector(
    (state) => state.manageClassLearnerDetail.initialState.status,
  )
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const status = _.get(item, 'status', 'WAITING')
  const bodyEvent = {
    learnerUuid: uuid,
    date: _.get(item, 'date', ''),
    round: _.get(item, 'round', ''),
  }
  const isStudy = status === 'STUDY'
  const isMissing = status === 'MISSING'
  const isAbsent = learnerStatus === 'ABSENT'

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', ml: -1 }}>
      <IconButton
        disabled={isStudy || isAbsent}
        onClick={() =>
          dispatch(events.handleDialogAction({ ...bodyEvent, type: 'STUDY' }))
        }
      >
        <Check color={isStudy || isAbsent ? 'action.active' : 'success'} />
      </IconButton>
      <IconButton
        disabled={isMissing || isAbsent}
        onClick={() =>
          dispatch(events.handleDialogAction({ ...bodyEvent, type: 'MISSING' }))
        }
      >
        <Close color={isMissing || isAbsent ? 'action.active' : 'error'} />
      </IconButton>
    </Box>
  )
}

const Index = () => {
  const dispatch = useDispatch()
  const { status, attendance, selectDate } = useSelector((state) => ({
    selectDate: state.manageClassLearnerDetail.selectDate,
    attendance: state.manageClassLearnerDetail.initialState.attendance,
    status: state.manageClassLearnerDetail.initialState.status,
  }))
  const { id: uuid } = useParams()

  useEffect(() => {
    dispatch(events.filterData({ attendance, selectDate }))
  }, [selectDate, attendance])

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <Box sx={{ m: 3 }}>
          <Row justifyContent="space-between">
            <Typography variant="h6">การเข้าเรียน</Typography>
            {!['CANCELED', 'CANCEL', 'CANCELED_MA', 'CANCEL_CLASS'].includes(
              status,
            ) && (
              <FormControlLabel
                label="ขาดเรียน"
                control={<Switch checked={status === 'ABSENT'} />}
                onChange={(e) => dispatch(events.handleSwitchChange(e, uuid))}
              />
            )}
          </Row>
          <Select
            dataTestid="select-date"
            required
            disabled={!events.filterListDate(attendance).length}
            id="type"
            name="type"
            labelText="วันที่เข้าเรียน"
            type="basic"
            options={events.filterListDate(attendance)}
            handleChange={(e) =>
              dispatch(
                events.handleChange({
                  key: 'selectDate',
                  value: _.get(e, 'target.value', selectDate),
                }),
              )
            }
            value={selectDate}
          />
          <Table type="details" />
        </Box>
      </Card>
    </>
  )
}
export default Index
