import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import ECertification from '../../../../components/ECertification'
import { getStateValue } from '../../events'
import {
  handleECertChange,
  handleECertChangeEvent,
  refCertField,
} from './events'

const ECertificationForm = () => {
  const eCertProp = useSelector(
    (state) => ({
      eCertificateList: state.eLearningPathForm.eCertificateList,
      isECertLoading: state.eLearningPathForm.isECertLoading,
      body: {
        ...getStateValue(refCertField, {}),
        certificatePrefix: getStateValue('certificatePrefix'),
      },
    }),
    shallowEqual,
  )
  return (
    <ECertification
      {...eCertProp}
      handleChange={handleECertChange}
      handleChangeEvent={handleECertChangeEvent}
    />
  )
}

export default ECertificationForm
