import _ from 'lodash'
import { store } from '../../../../App'
import { path } from '../../../../constants/path'
import { closeDialog, openDialog } from '../../../../redux/slices/dialog'
import {
  setBody,
  startLoading,
  stopLoading,
  setInitialCreateForm,
  startOptionLoading,
  stopOptionLoading,
  setFieldValue,
  setChange,
  startECertLoading,
  stopECertLoading,
} from '../../../../redux/slices/eLearning/courseForm'
import { getDetail } from '../../../../services/eLearning/course/detail'
import {
  getAllCourse,
  getAllDistributionChannel,
  getAllProductType,
} from '../../../../services/manageContent'
import {
  getAcquiredSkillList,
  getECertificationList,
  getELearningList,
  getLicenseList,
  getModuleList,
  postCreateCourse,
  putCreateCourse,
  getAdminList,
  getTrainerList,
  postCreateCourseDraft,
  putCreateCourseDraft,
  getTrainerQuestion,
} from '../../../../services/eLearning/course/form'
import { getLearningPointList } from '../../../../services/learningPoint/settingPoint'
import {
  filterDataPrerequisite,
  filterSpeakerProductType,
} from '../../../../modules/CourseSetting/Prerequisite/events'
import { COURSE_TYPE } from '../../../../constants/eLearning'
import { setFieldUploadValue } from '../../../../redux/slices/uploadCropper'
import { handleChangeTab } from './TabMenu/event'
import { FORM_TAB } from './model'

export const fetchCourseDetail = (props) => async (dispatch) => {
  const { uuid, createType, onEdit, setOnEdit, tab } = props
  if (onEdit) return

  dispatch(startLoading())
  dispatch(handleChangeTab(FORM_TAB.course))

  if (_.isEmpty(uuid)) {
    dispatch(setBody(null))
    dispatch(initialUploadCropper(null))
    const initialCreate = { courseType: _.upperCase(createType) }
    dispatch(setInitialCreateForm(initialCreate))
    await dispatch(fetchOptions())
  } else {
    dispatch(setBody(null))
    dispatch(fetchOptions())
    const response = await dispatch(getDetail(uuid))
    dispatch(initialUploadCropper(_.get(response, 'payload.data', {})))
    if (tab) dispatch(handleChangeTab(tab))
  }

  setOnEdit(true)
  dispatch(stopLoading())
}

export const initialUploadCropper = (data) => (dispatch) => {
  dispatch(
    setFieldUploadValue({
      key: 'fileName',
      value: _.get(data, 'fileName', ''),
    }),
  )
  dispatch(
    setFieldUploadValue({
      key: 'coverImage',
      value: _.get(data, 'coverImage', ''),
    }),
  )
}

export const handleGetModuleList = () => async (dispatch) => {
  dispatch(startOptionLoading())
  await dispatch(getModuleList())
  dispatch(stopOptionLoading())
}

export const handleGetECertificationList = () => async (dispatch) => {
  dispatch(startECertLoading())
  await dispatch(getECertificationList())
  dispatch(stopECertLoading())
}

export const fetchOptions = () => async (dispatch) => {
  dispatch(getAllDistributionChannel())
  dispatch(getLicenseList())
  dispatch(getAllCourse())
  dispatch(getAdminList())
  dispatch(getTrainerList())
  dispatch(getTrainerQuestion())
  dispatch(getELearningList())
  dispatch(getAllProductType({ order: 'ASC', sort: 'productType' }))
  dispatch(handleGetModuleList())
  dispatch(handleGetECertificationList())
  await dispatch(getAcquiredSkillList())
  await dispatch(getLearningPointList())
}

export const handleSubmit = (values, history) => (dispatch) => {
  if (values.isDraft) {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกฉบับร่าง',
        message: 'คุณต้องการบันทึกฉบับร่างนี้หรือไม่',
        handleConfirm: () => dispatch(handleConfirmSubmit(values, history)),
      }),
    )
  } else {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกและเผยแพร่',
        message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
        handleConfirm: () => dispatch(handleConfirmSubmit(values, history)),
      }),
    )
  }
}

export const handleConfirmSubmit = (values, history) => async (dispatch) => {
  dispatch(closeDialog())

  const eCerVerField =
    typeof values.eCertificationVersion === 'string'
      ? 'eCertificationVersion'
      : 'eCertificationVersion.uuid'
  const eLearningCourseLesson = _.map(
    values.eLearningCourseLesson,
    (lesson, index) => ({
      ...lesson,
      sequence: index + 1,
      eLearningLessonRefModule: _.map(
        lesson.eLearningLessonRefModule,
        (item, mIndex) => {
          const newItem = { ...item }
          delete newItem.eLearningCourseLesson
          return {
            ...newItem,
            sequence: mIndex + 1,
            eLearningModule: {
              id: _.get(item.eLearningModule, 'id', ''),
              uuid: _.get(item.eLearningModule, 'uuid', ''),
            },
          }
        },
      ),
    }),
  )
  const levelOfLearner = _.get(values, 'levelOfLearner', []).map((level) => ({
    label: _.get(level, 'title', _.get(level, 'label', '')),
    value: _.get(level, 'uuid', ''),
    uuid: _.get(level, 'uuid', ''),
  }))
  const distribution = _.get(values.setting, 'distribution', [])
  const sellCourse = _.get(values.setting, 'sellCourse', {})
  const collectHours = _.get(values.setting, 'collectHours', {})
  const setting = {
    ...values.setting,
    distribution: distribution.map((item) => ({
      label: item.label,
      value: item.value,
    })),
    sellCourse: {
      ...sellCourse,
      cost: sellCourse.isSell ? handleStringToNumber(sellCourse.cost) : null,
    },
    collectHours: {
      ...collectHours,
      hours: collectHours.isCollect
        ? handleStringToNumber(collectHours.hours)
        : null,
    },
  }
  const body = {
    ...values,
    point: parseInt(values.point) || 0,
    setting,
    eLearningCourseLesson,
    levelOfLearner,
    totalHour: Number(_.get(values, 'totalHour', '')),
    eCertificationVersion: _.get(values, eCerVerField, null),
    speakerProductType: filterSpeakerProductType(
      _.get(values, 'speakerProductType', []),
    ),
    coursePrerequisite: filterDataPrerequisite(
      _.get(values, 'coursePrerequisite', []),
    ),
  }

  dispatch(startLoading())
  let response = {}
  const isCreate = _.isEmpty(values.uuid)
  const isDraft = values.isDraft

  if (!isDraft) {
    if (isCreate) {
      response = await dispatch(postCreateCourse(body))
    } else {
      response = await dispatch(putCreateCourse(body))
    }
  } else {
    if (isCreate) {
      response = await dispatch(postCreateCourseDraft(body))
    } else {
      response = await dispatch(putCreateCourseDraft(body))
    }
  }
  dispatch(stopLoading())

  const uuid = _.get(response.payload, 'uuid', '')
  if (_.isNil(response.error)) {
    const isOIC = _.get(values, 'courseType', '') === COURSE_TYPE.OIC
    const editPath = `${path.eLearning}/course/edit/${uuid}`
    if (isOIC && isCreate) {
      const batchPath = `${path.eLearning}/course/edit/${uuid}?tab=batch`
      dispatch(
        openDialog({
          isCloseDialog: false,
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          agreeText: 'ตกลง',
          handleConfirm: () => {
            history.push(editPath)
            dispatch(closeDialog())
          },
          disagreeText: 'ไปเพิ่มรอบอบรม',
          colorDisagreeText: 'primary',
          variantDisagreeText: 'outlined',
          handleCancel: () => {
            history.push(batchPath)
            dispatch(closeDialog())
          },
        }),
      )
    } else {
      dispatch(
        openDialog({
          isCloseDialog: false,
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          agreeText: 'ตกลง',
          handleConfirm: () => {
            if (isCreate) window.location.href = editPath
            else history.go(0)
            dispatch(closeDialog())
          },
        }),
      )
    }
  } else if (
    response.meta.response.data.property === 'speakerProductTypeNotMatch'
  ) {
    dispatch(getTrainerList())
  }
}

export const handleStringToNumber = (value) => {
  if (_.isNil(value)) return 0
  return _.isNumber(value) ? value : Number(value.replaceAll(',', ''))
}

export const getCourseFormState = () => {
  const state = store.getState()
  return _.get(state, 'eLearningCourseForm.body', {})
}

export const getStateValue = (key, defaultValue = '') => {
  const courseFormState = getCourseFormState()
  return _.get(courseFormState, key, defaultValue)
}

export const handleChange = (key, value, isRoot) => (dispatch) => {
  const obj = { key, value }
  if (isRoot) dispatch(setChange(obj))
  else dispatch(setFieldValue(obj))
}

export const handleChangeEvent = (e) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  dispatch(setFieldValue({ key, value }))
}

export const handleInclude = (list, option) => {
  return list.some((item) => item.value === option.value)
}
