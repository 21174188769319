export const initialAttendance = {
  isAttendance: false,
  attendanceCount: 0,
  attendanceCriteria: 0,
  qrCodeTime: 0,
}

export const documentDefault = ['บัตรประชาชน', 'สลิปโอนเงินค่าสมัคร']

export const initialDocumentation = {
  requireDocument: false,
  documentList: [],
}

export const initialOicExam = {
  requireOicExam: false,
  agentConfirmation: false,
}

export const initialDocumentationTrue = {
  requireDocument: true,
  documentList: [
    {
      uuid: 1,
      name: 'บัตรประชาชน',
      isSelect: false,
      isDefault: true,
    },
    {
      uuid: 2,
      name: 'สลิปโอนเงินค่าสมัคร',
      isSelect: false,
      isDefault: true,
    },
  ],
}

export const initialAttendanceTrue = {
  isAttendance: true,
  attendanceCount: '',
  attendanceCriteria: '',
  qrCodeTime: '',
}

export const initialTestAndEvaluate = {
  courseVersionUuid: '',
  testAndEvaluateType: 'E_TESTING', // E-TESTING | E-EVALUATION
  testOrEvaluateUuid: '',
  type: '',
  otherType: '',
  testCriteriaType: 'NOT_REQUIRED',
  criteriaPercent: null,
}

export const defaultCoursePlatform = {
  englishName: '',
  thaiName: '',
  nameForLearner: '',
  startDate: null,
  finishDate: null,
  hours: '',
  minutes: null,
  englishCertificateName: '',
  imageKey: '',
  distribution: {
    id: null,
    uuid: '',
    distribution: '',
    abbreviation: '',
    level: [],
    status: '',
    isDelete: false,
    remark: '',
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
  },
  roadmap: {
    uuid: '',
    abbreviation: '',
    title: '',
    remark: '',
    distribution: [],
    status: '',
    createdBy: '',
    updatedBy: '',
    id: null,
    isDelete: false,
    createdAt: '',
    updatedAt: '',
  },
  levelOfLearner: [],
  otherLevelOfLearner: [],
  trainingPlatform: [],
  courseCode: '',
  acquiredSkill: [],
  productType: [],
  speakerProductType: [[]],
  certificateLanguage: null,
  certificatePrefix: 'true',
  coursePurpose: '',
  courseOutline: '',
  courseLevelControl: '',
  courseCondition: [[{ type: '', uuid: '', name: '' }]],
  isApproved: false,
  hasBreakMeal: false,
  breakMealBudget: 0,
  status: '',
  isActive: true,
  assignee: [],
  isAttendance: false,
  attendanceCount: 0,
  attendanceCriteria: 0,
  qrCodeTime: 0,
  testAndEvaluate: [],
  eCertificationVersion: null,
  coursePrerequisite: [],
  electronic: [],
  tag: [],
}

export const initailSettingPoint = {
  pointStatus: 'INACTIVE',
  pointType: 'CUSTOM',
  point: '',
  learningPoint: '',
}
