import _ from 'lodash'
import { statusList } from './model'

export const findItem = ({ list, item, isYear }) => {
  if (!list.length) return null

  const type = _.get(item, 'type', '')
  let value = ''
  if (type === 'COURSE') value = _.get(item, 'coursePrerequisiteUuid', null)
  if (type === 'LICENSE') value = _.get(item, 'licenseUuid', null)
  if (type === 'OTHER') value = _.get(item, 'otherType', null)
  if (isYear)
    value = _.get(item, 'year', null) ? Number(_.get(item, 'year', null)) : null

  const result = list.find((item) => item.value === value)
  return result || null
}

export const mapKeyValue = (type) => {
  let keyValue = ''
  if (type === 'COURSE') keyValue = 'coursePrerequisiteUuid'
  if (type === 'LICENSE') keyValue = 'licenseUuid'
  if (type === 'OTHER') keyValue = 'otherType'
  return keyValue
}

export const handleChangeSelect = ({
  listIndex,
  contentIndex,
  itemIndex,
  coursePrerequisite,
  formik,
  keyValue,
  value,
}) => {
  const result = { ...coursePrerequisite[listIndex][contentIndex][itemIndex] }
  result['coursePrerequisiteUuid'] = null
  result['licenseUuid'] = null
  result['otherType'] = null

  if (keyValue !== 'month') result['year'] = null
  if (keyValue !== 'year') result['month'] = null

  const newResult = { ...result, [keyValue]: value }
  formik.setFieldValue(
    `coursePrerequisite[${listIndex}][${contentIndex}][${itemIndex}]`,
    newResult,
  )
}

export const handleDeleteByCard = ({
  formik,
  coursePrerequisite,
  listIndex,
}) => {
  const result = [...coursePrerequisite]
  result.splice(listIndex, 1)
  formik.setFieldValue('coursePrerequisite', result)
}

export const handleAddCondition = ({ formik, coursePrerequisite }) => {
  const courseGroup = coursePrerequisite.length
  const result = [
    [
      {
        courseGroup: courseGroup + 1,
        subGroup: 1,
        type: '',
        coursePrerequisiteUuid: null,
        licenseUuid: null,
        year: null,
        month: null,
        otherType: null,
      },
    ],
  ]
  formik.setFieldValue('coursePrerequisite', [...coursePrerequisite, result])
}

export const handleAddSubGroup = ({
  formik,
  coursePrerequisite,
  listIndex,
  contentIndex,
  itemIndex,
  item,
}) => {
  const courseGroup = _.get(item, 'courseGroup', 1)
  const subGroup = _.get(item, 'subGroup', 1)
  const result = [...coursePrerequisite[listIndex][contentIndex]]
  result.splice(itemIndex + 1, 0, {
    courseGroup,
    subGroup,
    type: '',
    coursePrerequisiteUuid: null,
    licenseUuid: null,
    year: null,
    month: null,
    otherType: null,
  })

  formik.setFieldValue(
    `coursePrerequisite[${listIndex}][${contentIndex}]`,
    result,
  )
}

export const handleDeleteSubGroup = ({
  formik,
  coursePrerequisite,
  listIndex,
  contentIndex,
  itemIndex,
  itemContent,
}) => {
  if (itemContent.length <= 1) {
    const result = [...coursePrerequisite[listIndex]]
    result.splice(contentIndex, 1)
    coursePrerequisite[listIndex] = result
  } else {
    const result = [...coursePrerequisite[listIndex][contentIndex]]
    result.splice(itemIndex, 1)
    coursePrerequisite[listIndex][contentIndex] = result
  }

  formik.setFieldValue('coursePrerequisite', coursePrerequisite)
}

export const handleAddConditionInGroup = ({
  formik,
  coursePrerequisite,
  listIndex,
}) => {
  const courseGroup = _.get(
    coursePrerequisite,
    `[${listIndex}][0][0].courseGroup`,
    1,
  )
  const subGroup = _.get(coursePrerequisite, `[${listIndex}]`, []).length

  const result = [
    {
      courseGroup: courseGroup,
      subGroup: subGroup + 1,
      type: '',
      coursePrerequisiteUuid: null,
      licenseUuid: null,
      year: null,
      month: null,
      otherType: null,
    },
  ]
  coursePrerequisite[listIndex] = [...coursePrerequisite[listIndex], result]
  formik.setFieldValue('coursePrerequisite', coursePrerequisite)
}

export const mapListOptionSelect = ({ license, allCourse }) => {
  let result = {
    listOptionCourse: [],
    listOptionLicense: [],
    listOptionOther: [
      {
        label: 'ตัวแทนที่ใบอนุญาตผู้แนะนำการลงทุนหมดอายุในปีที่อบรมนั้น',
        value: 'LC_EXP_IN_YEAR',
      },
    ],
  }
  const resultCourse = allCourse.map((item) => {
    let labelText = `${statusList[item.status].text} : ${item.courseCode} ${
      item.englishName || item.name
    }`
    return {
      label: labelText,
      value: item.uuid,
      sort: statusList[item.status].sort,
    }
  })
  const resultLicense = license.map((item) => {
    return { label: item.description, value: item.uuid }
  })

  result.listOptionCourse = _.orderBy(resultCourse, ['sort'], ['asc'])
  result.listOptionLicense = resultLicense.sort(
    (a, b) => -b.label.localeCompare(a.label),
  )

  return result
}

export const conditionDisabled = (item) => {
  if (!item.type) return true
  if (
    item.type !== 'SERVICEYEAR' &&
    !item.coursePrerequisiteUuid &&
    !item.licenseUuid &&
    !item.otherType
  ) {
    return true
  } else if (
    item.type == 'SERVICEYEAR' &&
    (_.isEmpty(`${item.year}`) || _.isEmpty(`${item.month}`))
  ) {
    return true
  }
  return false
}

export const checkDisabled = (itemContent) => {
  let result = false
  for (const item of itemContent) {
    if (conditionDisabled(item)) {
      result = true
      break
    }
  }
  return result
}

export const mapOptionSelect = (type, listOptionSelect) => {
  return type == 'COURSE'
    ? listOptionSelect.listOptionCourse
    : type == 'LICENSE'
    ? listOptionSelect.listOptionLicense
    : listOptionSelect.listOptionOther
}

export const filterDataPrerequisite = (coursePrerequisite, isClone) => {
  if (!coursePrerequisite.length) return []
  let result = []
  coursePrerequisite.map((itemList) => {
    let resList = []
    itemList.filter((itemContent) => {
      let listItem = []
      itemContent.filter((item) => {
        const uuid = _.get(item, 'uuid', '')
        if (item.type) {
          let values = {
            courseGroup: _.get(item, 'courseGroup', 0),
            subGroup: _.get(item, 'subGroup', 0),
            type: _.get(item, 'type', ''),
            coursePrerequisiteUuid: _.get(item, 'coursePrerequisiteUuid', ''),
            licenseUuid: _.get(item, 'licenseUuid', ''),
            year: Number(_.get(item, 'year', 0)),
            month: Number(_.get(item, 'month', 0)),
            otherType: _.get(item, 'otherType', ''),
          }
          if (!isClone && !_.isEmpty(uuid)) values = { ...values, uuid }
          listItem.push(values)
        }
      })
      if (listItem.length) resList.push(listItem)
    })
    if (resList.length) result.push(resList)
  })

  return result
}

export const filterSpeakerProductType = (speakerProductType) => {
  if (!speakerProductType.length) return []
  let result = []
  for (const i of speakerProductType) {
    if (i.length) {
      result.push(i)
    }
  }
  return result
}

export const validateSchemaPrerequisite = (item) => {
  if (_.isEmpty(item.type)) return true

  switch (item.type) {
    case 'COURSE':
      return !_.isEmpty(item.coursePrerequisiteUuid)
    case 'LICENSE':
      return !_.isEmpty(item.licenseUuid)
    case 'OTHER':
      return !_.isEmpty(item.otherType)
    default:
      return true
  }
}
