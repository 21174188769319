import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from '../Styled'
import { handleClearFilter } from '../../handler/handleClearFilter'
import dayjs from 'dayjs'

export const FilterBox = () => {
  const { mixModuleDashboard } = useSelector(
    (state) => ({
      mixModuleDashboard: state.crud.mixModuleDashboard,
    }),
    shallowEqual,
  )

  const filter = mixModuleDashboard?.filtersDrawer
  const isFilterDate = filter?.isFilterDate ?? ''
  const startDateFilter = isFilterDate
    ? dayjs(filter.dateFilterStartDate).format('YYYY-MM-DD')
    : dayjs().startOf('month').format('YYYY-MM-DD')
  const endDateFilter = isFilterDate
    ? dayjs(filter.dateFilterFinishDate).format('YYYY-MM-DD')
    : dayjs().format('YYYY-MM-DD')
  const hasFilter = isFilterDate || (startDateFilter && endDateFilter)
  return (
    <>
      {hasFilter ? (
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่จัดอบรม:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterDate')}
                />
              )}
            </FilterStyledBox>
          )}
        </BoxWrapper>
      ) : (
        ''
      )}
    </>
  )
}
