import _ from 'lodash'

export const handleInitialForm = (initialValues, formik) => {
  const eCertificationVersion = _.get(
    initialValues,
    'eCertificationVersion',
    null,
  )
  const expireECertificationYear = _.get(
    initialValues,
    'expireECertificationYear',
    null,
  )
  const certificatePrefix =
    _.get(initialValues, 'certificatePrefix') !== undefined
      ? _.get(initialValues, 'certificatePrefix')
      : _.get(formik.values, 'certificatePrefix', true)

  formik.setFieldValue('eCertificationVersion', eCertificationVersion)
  formik.setFieldValue('expireECertificationYear', expireECertificationYear)
  formik.setFieldValue('certificatePrefix', certificatePrefix)
}
