import React from 'react'
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone'
import AdminPanelSettingsTwoTone from '@mui/icons-material/AdminPanelSettingsTwoTone'
import InsertInvitationTwoTone from '@mui/icons-material/InsertInvitationTwoTone'
import CollectionsBookmarkTwoTone from '@mui/icons-material/CollectionsBookmarkTwoTone'
import MenuBookTwoTone from '@mui/icons-material/MenuBookTwoTone'
import AssessmentTwoTone from '@mui/icons-material/AssessmentTwoTone'
import VerifiedTwoTone from '@mui/icons-material/VerifiedTwoTone'
import SettingsSuggestTwoTone from '@mui/icons-material/SettingsSuggestTwoTone'
import BadgeTwoTone from '@mui/icons-material/BadgeTwoTone'
import ManageAccountsTwoTone from '@mui/icons-material/ManageAccountsTwoTone'
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone'
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone'
import SchoolTwoTone from '@mui/icons-material/SchoolTwoTone'
import HandymanIcon from '@mui/icons-material/Handyman'
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone'
import LocalLibraryTwoToneIcon from '@mui/icons-material/LocalLibraryTwoTone'
import HistoryIcon from '@mui/icons-material/History'
import AllInclusiveIcon from '@mui/icons-material/AllInclusiveTwoTone'

import { validateEPermission, validatePermission } from '../../utils/lib'
import { useLocalStorage } from '@rehooks/local-storage'
import {
  E_CERTIFICATE_SETTING,
  PERMISSION,
} from '../../constants/eCertification'
import { AGENT_LICENSE_SETTING } from '../../constants/permission/apiToRcms'
import { AGENT_PROFILE_SETTING } from '../../constants/permission/agentProfile'
import { CS_AGENT_PROFILE_SETTING } from '../../constants/permission/csAgentProfile'
import { PROSPECT_PROFILE_SETTING } from '../../constants/permission/prospectProfile'
import { CS_USER_PROFILE_SETTING } from '../../constants/permission/csUserProfile'
import { E_EVALUATION_SETTING } from '../../constants/eEvaluation'
import { eTestingPermission } from '../../constants/eTesting'
import {
  EXAMINATION_SETTING,
  PERMISSION as ExamPermission,
} from '../../constants/examination'
import { ROOMMANAGEMENT_SETTING } from '../../constants/roomManagement'
import {
  MonetizationOnTwoTone,
  RoomPreferencesTwoTone,
} from '@mui/icons-material'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone'
import { INVENTORY_SETTING, STOCK_PERMISSION } from '../../constants/stock'
import {
  CONFIGURATION_SETTING,
  CONFIG_PERMISSION,
} from '../../constants/eConfig'
import { PERMISSION_REPORT_E_EVALUATION } from '../../constants/report/ReportEEvaRawDataByCourse'
import { PERMISSION_REPORT_EXPENSE } from 'src/constants/report/expense'

export const checkColor = (status) => {
  return status ? 'primary.light' : 'unset'
}

export const leftMenuList = () => {
  const menu = [
    {
      name: 'รายการที่ต้องทำ',
      icon: (active) => (
        <AssignmentTwoToneIcon
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/to-do-list',
      list: [],
    },
    {
      name: 'Room Management',
      icon: (active) => (
        <RoomPreferencesTwoTone
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/room-management',
      list: [
        {
          name: 'การจองห้อง',
          active: false,
          path: '/room-management',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/room-management/dashboard',
        },
        {
          name: 'รายงาน',
          active: false,
          path: '/room-management/report',
        },
      ],
    },
    {
      name: 'จัดการหลักสูตร',
      icon: (active) => (
        <CollectionsBookmarkTwoTone
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '',
      list: [
        {
          name: 'หลักสูตร',
          active: false,
          path: '/course-setting',
        },
        {
          name: 'ภาพรวม',
          active: false,
          path: '/report-course-setting/dashboard',
        },
        {
          name: 'รายงาน',
          active: false,
          path: '/report-course-setting',
        },
      ],
    },
    {
      name: 'จัดการคลาส',
      icon: (active) => (
        <MenuBookTwoTone fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/manage-class',
      list: [
        {
          name: 'คลาสทั้งหมด',
          active: false,
          path: '/manage-class',
        },
        {
          name: 'ตรวจข้อสอบ',
          active: false,
          path: '/manage-class/check-exam/list',
        },
        {
          name: 'ผลสอบใบอนุญาตตัวแทน',
          active: false,
          path: '/manage-class/oic',
        },
        {
          name: 'รายงาน Workload TO',
          active: false,
          path: '/manage-class/report-workload-to',
        },
        {
          name: 'รายงาน For HF',
          active: false,
          path: '/manage-class/report-for-hf',
        },
        {
          name: 'รายงาน Export by Learner',
          active: false,
          path: '/manage-class/report-export-by-learner',
        },
        {
          name: 'รายงาน Export by Class',
          active: false,
          path: '/manage-class/report-export-by-class',
        },
        {
          name: 'ภาพรวม',
          active: false,
          path: '/manage-class/dashboard',
        },
      ],
    },
    {
      name: 'Learning Point Management',
      icon: (active) => (
        <EmojiEventsTwoToneIcon
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/learning-point',
      list: [
        {
          name: 'ตั้งค่าคะแนน',
          active: false,
          path: '/learning-point/setting-point',
        },
        {
          name: 'Catalog Management',
          active: false,
          path: '/learning-point/catalog',
        },
        // {
        //   name: 'รายงาน',
        //   active: false,
        //   path: '/learning-point',
        // },
      ],
    },
    {
      name: 'แผนรายเดือน',
      icon: (active) => (
        <InsertInvitationTwoTone
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/monthly-plan',
      list: [
        {
          name: 'จัดการแผนงาน',
          active: false,
          path: '/monthly-plan/calendar',
        },
        {
          name: 'คำขอยืมวิทยากรจากโซนอื่น',
          active: false,
          path: '/monthly-plan/speaker-requester',
        },
        {
          name: 'รายการยืมวิทยากร',
          active: false,
          path: '/monthly-plan/speaker-approver',
        },
        {
          name: 'Class Dashboard',
          active: false,
          path: '/monthly-plan/class/dashboard',
        },
        {
          name: 'Workload Dashboard',
          active: false,
          path: '/monthly-plan/workload/dashboard',
        },
        {
          name: 'รายงาน Workload',
          active: false,
          path: '/monthly-plan/report',
        },
      ],
    },
    {
      name: 'E-Learning',
      icon: (active) => (
        <SchoolTwoTone fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/e-learning',
      list: [
        {
          name: 'Dashboard',
          active: false,
          path: '/e-learning/dashboard',
        },
        {
          name: 'Module',
          active: false,
          path: '/e-learning/module',
        },
        {
          name: 'Course',
          active: false,
          path: '/e-learning/course',
        },
        {
          name: 'Learning path',
          active: false,
          path: '/e-learning/learning-path',
        },
        {
          name: 'Collection',
          active: false,
          path: '/e-learning/collection',
        },
        {
          name: 'ตอบคำถาม',
          active: false,
          path: '/e-learning/question',
        },
      ],
    },
    {
      name: 'E-Content',
      icon: (active) => (
        <LocalLibraryTwoToneIcon
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/e-content',
      list: [
        {
          name: 'คลังความรู้',
          active: false,
          path: '/e-content/content',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/e-content/dashboard',
        },
        {
          name: 'รายงาน',
          active: false,
          path: '/e-content/report',
        },
      ],
    },
    {
      name: 'E-Examination',
      icon: (active) => (
        <InsertDriveFileOutlinedIcon
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/e-examination',
      list: [
        {
          name: 'ตารางสอบ',
          active: false,
          path: '/e-examination/list',
        },
        {
          name: 'รายงานหักบัญชี',
          active: false,
          path: '/e-examination/report',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/e-examination/dashboard',
        },
        {
          name: 'รายงานผลสอบ',
          active: false,
          path: '/e-examination/report-result',
        },
        {
          name: 'รายงานรอบสอบ',
          active: false,
          path: '/e-examination/report-schedule',
        },
        {
          name: 'รายงานการรับสมัคร',
          active: false,
          path: '/e-examination/report-enroll',
        },
      ],
    },
    {
      name: 'E-Testing',
      icon: (active) => (
        <SummarizeTwoToneIcon
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/e-testing',
      list: [
        {
          name: 'แบบทดสอบทั้งหมด',
          active: false,
          path: '/e-testing',
        },
        {
          name: 'ตรวจข้อสอบ',
          active: false,
          path: '/e-testing/check-exam/list',
        },
        {
          name: 'Report Raw Data By Class',
          active: false,
          path: '/e-testing/report-raw-data-by-class',
        },
        {
          name: 'Report Raw Data By Learner',
          active: false,
          path: '/e-testing/report-raw-data-by-learner',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/e-testing/dashboard',
        },
      ],
    },
    {
      name: 'E-Evaluation',
      icon: (active) => (
        <AssessmentTwoTone fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/e-evaluation',
      list: [
        {
          name: 'E-Evaluation',
          active: false,
          path: '/e-evaluation/',
        },
        {
          name: 'Report Raw Data By Course',
          active: false,
          path: '/e-evaluation/report-raw-data-by-course',
        },
        {
          name: 'Report Raw Data By Trainer',
          active: false,
          path: '/e-evaluation/report-raw-data-by-trainer',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/e-evaluation/dashboard',
        },
      ],
    },
    {
      name: 'E-Certificate',
      icon: (active) => (
        <VerifiedTwoTone fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/e-certification',
      list: [],
    },
    {
      name: 'API to RCMS',
      icon: (active) => (
        <BadgeTwoTone fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/api-to-rcms',
      list: [
        {
          name: 'Agent License',
          active: false,
          path: '/api-to-rcms',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/api-to-rcms/dashboard',
        },
        {
          name: 'รายงาน',
          active: false,
          path: '/api-to-rcms/report',
        },
      ],
    },
    {
      name: 'Inventory',
      icon: (active) => (
        <ViewInArTwoToneIcon fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/inventory',
      list: [
        {
          name: 'การเบิก - คืนทรัพย์สิน',
          active: false,
          path: '/inventory/requisition',
        },
        {
          name: 'โอนย้าย',
          active: false,
          path: '/inventory/transfer',
        },
        {
          name: 'ทรัพย์สิน',
          active: false,
          path: '/inventory/product',
        },
        {
          name: 'คลังทรัพย์สิน',
          active: false,
          path: '/inventory/stock',
        },
        {
          name: 'Vendor Management',
          active: false,
          path: '/inventory-data/vendor-management',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/inventory/dashboard',
        },
        {
          name: 'Report Raw Data Requisition',
          active: false,
          path: '/inventory/report-raw-data-requisition',
        },
        {
          name: 'Report Raw Data Asset&License',
          active: false,
          path: '/inventory/report-raw-data-asset-license',
        },
        {
          name: 'Report Raw Data Stock',
          active: false,
          path: '/inventory/report-raw-data-stock',
        },
      ],
    },
    {
      name: 'Expense',
      icon: (active) => (
        <MonetizationOnTwoTone
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/budget-management',
      list: [
        {
          name: 'Budget Management',
          active: false,
          path: '/budget-management',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/expense/dashboard',
        },
        {
          name: 'รายงาน',
          active: false,
          path: '/expense/report',
        },
      ],
    },
    {
      name: 'Master Data',
      icon: (active) => (
        <AdminPanelSettingsTwoTone
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/master-data',
      list: [
        {
          name: 'ช่องทางการจำหน่าย',
          active: false,
          path: '/master-data/distribution-channel',
        },
        {
          name: 'ระดับของผู้เรียน',
          active: false,
          path: '/master-data/level-of-learner',
        },
        {
          name: 'กลุ่มหลักสูตร',
          active: false,
          path: '/master-data/road-map',
        },
        {
          name: 'ประเภทของทักษะ',
          active: false,
          path: '/master-data/skill-type',
        },
        {
          name: 'ระดับหลักสูตร',
          active: false,
          path: '/master-data/acquired-skill',
        },
        {
          name: 'ทักษะที่ได้จากหลักสูตร',
          active: false,
          path: '/master-data/product-type',
        },
        {
          name: 'รูปแบบการสอน',
          active: false,
          path: '/master-data/training-platform',
        },
        {
          name: 'Level',
          active: false,
          path: '/master-data/level',
        },
        {
          name: 'Job Title',
          active: false,
          path: '/master-data/job-title',
        },
        {
          name: 'ใบอนุญาต',
          active: false,
          path: '/master-data/license',
        },
        {
          name: 'ห้องประชุม',
          active: false,
          path: '/master-data/meeting-room',
        },
        {
          name: 'สนามสอบ',
          active: false,
          path: '/master-data/examination',
        },
        {
          name: 'Station',
          active: false,
          path: '/master-data/station',
        },
        {
          name: 'หมวดหมู่หลัก คลังความรู้',
          active: false,
          path: '/master-data/e-content/category',
        },
        {
          name: 'หมวดหมู่ย่อย คลังความรู้',
          active: false,
          path: '/master-data/e-content/sub-category',
        },
        {
          name: 'หมวดหมู่หลัก Inventory',
          active: false,
          path: '/master-data/inventory/category',
        },
        {
          name: 'หมวดหมู่ย่อย Inventory',
          active: false,
          path: '/master-data/inventory/sub-category',
        },
        {
          name: 'Account Code',
          active: false,
          path: '/master-data/account-code',
        },
        {
          name: 'Expense Category',
          active: false,
          path: '/master-data/expense-category',
        },
        {
          name: 'Cost Center',
          active: false,
          path: '/master-data/cost-center',
        },
      ],
    },
    {
      name: 'Manage',
      icon: (active) => (
        <ManageAccountsTwoTone
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/manage',
      list: [
        {
          name: 'ข้อมูล Agent',
          active: false,
          path: '/manage/agent-profile',
        },
        {
          name: 'ข้อมูล CS Agent',
          active: false,
          path: '/manage/cs-agent-profile',
        },
        {
          name: 'ข้อมูล Prospect',
          active: false,
          path: '/manage/prospect-profile',
        },
        {
          name: 'ข้อมูล CS User',
          active: false,
          path: '/manage/cs-user-profile',
        },
        {
          name: 'อัพเดทข้อมูล Agent',
          active: false,
          path: '/manage/agent-update-profile',
        },
        {
          name: 'ข้อมูลพนักงาน',
          active: false,
          path: '/manage/staff-profile',
        },
        {
          name: 'ข้อมูล Non Academy',
          active: false,
          path: '/manage/non-academy-profile',
        },
        {
          name: 'สิทธิ์การใช้งาน',
          active: false,
          path: '/manage/permission',
        },
        {
          name: 'Dashboard',
          active: false,
          path: '/manage/dashboard',
        },
        {
          name: 'รายงานข้อมูล Agent',
          active: false,
          path: '/manage/report-manage-profile/agent',
        },
        {
          name: 'รายงานข้อมูล Agent for TO',
          active: false,
          path: '/manage/report-manage-profile/agent-to',
        },
        {
          name: 'รายงานข้อมูล Agent for Trainer',
          active: false,
          path: '/manage/report-manage-profile/agent-trainer',
        },
        {
          name: 'รายงานข้อมูล Prospect',
          active: false,
          path: '/manage/report-manage-profile/prospect',
        },
        {
          name: 'รายงานข้อมูลพนักงาน',
          active: false,
          path: '/manage/report-manage-profile/staff',
        },
      ],
    },
    {
      name: 'Manage Content',
      icon: (active) => (
        <SettingsSuggestTwoTone
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/manage-content',
      list: [
        {
          name: 'Login',
          active: false,
          path: '/manage-content/login',
        },
        {
          name: 'Home',
          active: false,
          path: '/manage-content/home',
        },
        {
          name: 'Station',
          active: false,
          path: '/manage-content/station',
        },
        // {
        //   name: 'Home E-learning',
        //   active: false,
        //   path: '/manage-content/home-e-learning',
        // },
      ],
    },
    {
      name: 'Audit Log',
      icon: (active) => (
        <HistoryIcon fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/audit-log',
      list: [],
    },
    {
      name: 'Mix Module Report',
      icon: (active) => (
        <AllInclusiveIcon fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/รายงาน Mix Module',
      list: [
        {
          name: 'Dashboard',
          active: false,
          path: '/mix-module/dashboard',
        },
        {
          name: 'ผลการเรียนรายบุคคล',
          active: false,
          path: '/report/mix-module',
        },
        {
          name: 'RAW Monthly Plan + Manage Class',
          active: false,
          path: '/report/monthly-plan-and-manage-class',
        },
        {
          name: 'RAW Expense + Manage Class',
          active: false,
          path: '/report/manage-class-and-expense',
        },
      ],
    },
    {
      name: 'Report',
      icon: (active) => (
        <AnalyticsTwoToneIcon
          fontSize="sm"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/report',
      list: [
        {
          name: 'Raw Data Manage Class',
          active: false,
          path: '/report/raw-data',
        },
      ],
    },
    {
      name: 'Configuration',
      icon: (active) => (
        <HandymanIcon fontSize="sm" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/configuration',
      list: [],
    },
  ]
  const [user] = useLocalStorage('user')
  const hasApproveZone = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['APPROVE_ZONE', 'ASSISTS_ZONE'],
  })
  const hasMasterData = validatePermission({
    user,
    moduleType: 'MASTER_DATA',
    permission: ['CREATE', 'DELETE', 'VIEW'],
  })
  const hasCourseSetting = validatePermission({
    user,
    moduleType: 'COURSE_SETTING',
    permission: ['CREATE', 'DELETE', 'VIEW'],
  })
  const hasMonthlyPlanView = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: [
      'VIEW_ALL',
      'CREATE_ALL_EXC_HOLIDAY',
      'DELETE_ALL_EXC_HOLIDAY',
      'CREATE_ALL',
      'DELETE_ALL',
      'CREATE_LEAVE',
      'DELETE_LEAVE',
      'APPROVE_MP',
      'APPROVE_ZONE',
      'ASSISTS_ZONE',
    ],
  })

  const hasManagePermission = validatePermission({
    user,
    moduleType: 'MANAGE_PERMISSION',
    permission: ['CREATE', 'DELETE', 'VIEW'],
  })

  const hasManageClassMenu = validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: [PERMISSION.MENU],
  })

  const hasManageClassAllMenu = validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['MENU_ALL_CLASS'],
  })

  const hasManageClassExamMenu = validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['MENU_EXAM'],
  })

  const hasManageClassOicMenu = validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['MENU_CHECK_OIC'],
  })

  let filterMenu = menu
  if (!hasApproveZone)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'แผนรายเดือน') {
        menuItem.list = item.list.filter(
          (subMenu) =>
            !(
              subMenu.name === 'รายการยืมวิทยากร' ||
              subMenu.name === 'คำขอยืมวิทยากรจากโซนอื่น'
            ),
        )
      }
      return menuItem
    })

  const hasETesting = validateEPermission({
    module: eTestingPermission.E_TESTING_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasETestingExam = validateEPermission({
    module: eTestingPermission.E_TESTING_EXAM,
    permission: PERMISSION.MENU,
  })

  const hasEValuation = validateEPermission({
    module: E_EVALUATION_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasECertification = validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasApiToRcms = validateEPermission({
    module: AGENT_LICENSE_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasAgentProfile = validateEPermission({
    module: AGENT_PROFILE_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasCSAgentProfile = validateEPermission({
    module: CS_AGENT_PROFILE_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasProspectProfile = validateEPermission({
    module: PROSPECT_PROFILE_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasCSUserProfile = validateEPermission({
    module: CS_USER_PROFILE_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasRoomManagement = validateEPermission({
    module: ROOMMANAGEMENT_SETTING,
    permission: PERMISSION.MENU,
  })

  const hasExamination = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: ExamPermission.MENU,
  })

  const hasExaminationDc = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: ExamPermission.DC,
  })

  const hasEConfig = validateEPermission({
    module: CONFIGURATION_SETTING,
    permission: CONFIG_PERMISSION.ADMIN,
  })

  const hasELearning = validatePermission({
    user,
    moduleType: 'E_LEARNING',
    permission: ['HEAD_ADMIN', 'ADMIN', 'TRAINER'],
  })
  const hasELearningHeadAdmin = validatePermission({
    user,
    moduleType: 'E_LEARNING',
    permission: ['HEAD_ADMIN'],
  })
  const hasELearningAdmin = validatePermission({
    user,
    moduleType: 'E_LEARNING',
    permission: ['ADMIN'],
  })
  const hasLearningPoint = validatePermission({
    user,
    moduleType: 'LEARNING_POINT',
    permission: ['CATALOG', 'SETTING_POINT'],
  })
  const hasLearningPointCatalog = validatePermission({
    user,
    moduleType: 'LEARNING_POINT',
    permission: ['CATALOG'],
  })
  const hasLearningPointSettingPoint = validatePermission({
    user,
    moduleType: 'LEARNING_POINT',
    permission: ['SETTING_POINT'],
  })

  const hasReportCourseSetting = validatePermission({
    user,
    moduleType: 'REPORT_COURSE_SETTING',
    permission: ['REPORT'],
  })

  const hasDashboardCourseSetting = validatePermission({
    user,
    moduleType: 'REPORT_COURSE_SETTING',
    permission: ['DASHBOARD'],
  })

  const hasReportETestingDashboard = validatePermission({
    user,
    moduleType: 'REPORT_E_TESTING',
    permission: ['DASHBOARD'],
  })

  const hasReportETestingByClass = validatePermission({
    user,
    moduleType: 'REPORT_E_TESTING',
    permission: ['REPORT_BY_CLASS'],
  })

  const hasReportETestingByLearner = validatePermission({
    user,
    moduleType: 'REPORT_E_TESTING',
    permission: ['REPORT_BY_LEARNER'],
  })

  const hasReportManageClassByClass = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_CLASS',
    permission: ['REPORT_BY_CLASS'],
  })

  const hasReportManageClassByLearner = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_CLASS',
    permission: ['REPORT_BY_LEARNER'],
  })

  const hasReportManageClassWorkloadTo = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_CLASS',
    permission: ['REPORT_WORKLOAD_TO'],
  })

  const hasReportManageClassHF = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_CLASS',
    permission: ['REPORT_HF'],
  })

  const hasReportExamResult = validatePermission({
    user,
    moduleType: 'REPORT_E_EXAMINATION',
    permission: ['REPORT_RESULT'],
  })

  const hasReportExamSchedule = validatePermission({
    user,
    moduleType: 'REPORT_E_EXAMINATION',
    permission: ['REPORT_SCHEDULE'],
  })

  const hasReportExamEnroll = validatePermission({
    user,
    moduleType: 'REPORT_E_EXAMINATION',
    permission: ['REPORT_ENROLL'],
  })

  const hasDashboardExam = validatePermission({
    user,
    moduleType: 'REPORT_E_EXAMINATION',
    permission: ['DASHBOARD'],
  })

  const hasBudgetManagement = validatePermission({
    user,
    moduleType: 'EXPENSE',
    permission: ['ADMIN'],
  })
  const hasEContent = validatePermission({
    user,
    moduleType: 'E_CONTENT_AND_KB',
    permission: ['MENU'],
  })

  const hasReportDashboardMonthlyPlan = validatePermission({
    user,
    moduleType: 'REPORT_MONTHLY_PLAN',
    permission: ['REPORT_DASHBOARD'],
  })

  const hasReportManageProfileAgent = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_PROFILE',
    permission: ['REPORT_AGENT'],
  })

  const hasReportManageProfileProspect = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_PROFILE',
    permission: ['REPORT_PROSPECT'],
  })

  const hasReportManageProfileStaff = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_PROFILE',
    permission: ['REPORT_STAFF'],
  })

  const hasReportManageProfileAgentForTo = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_PROFILE',
    permission: ['REPORT_AGENT_TO'],
  })

  const hasReportManageProfileAgentForTrainer = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_PROFILE',
    permission: ['REPORT_AGENT_TRAINER'],
  })

  const hasDashboardManageProfile = validatePermission({
    user,
    moduleType: 'REPORT_MANAGE_PROFILE',
    permission: ['DASHBOARD'],
  })

  const hasSyncAgnet = validatePermission({
    user,
    moduleType: 'SYNC_AGENT',
    permission: ['ADMIN'],
  })

  const hasReportMixModule = validatePermission({
    user,
    moduleType: 'REPORT_MIX_MODULE',
    permission: ['REPORT'],
  })

  const hasReportRoomManagement = validatePermission({
    user,
    moduleType: 'REPORT_ROOM_MANAGEMENT',
    permission: ['DASHBOARD_REPORT'],
  })

  const hasEvaDashboard = validatePermission({
    user,
    moduleType: 'REPORT_E_EVALUATION',
    permission: ['DASHBOARD'],
  })

  const hasReportEValuationByCourse = validatePermission({
    user,
    moduleType: 'REPORT_E_EVALUATION',
    permission: [PERMISSION_REPORT_E_EVALUATION.REPORT_RAW_DATA_BY_COURSE],
  })

  const hasReportEValuationByTrainer = validatePermission({
    user,
    moduleType: 'REPORT_E_EVALUATION',
    permission: [PERMISSION_REPORT_E_EVALUATION.REPORT_RAW_DATA_BY_TRAINER],
  })

  const hasReportMixModuleDashboard = validatePermission({
    user,
    moduleType: 'REPORT_MIX_MODULE',
    permission: ['REPORT_DASHBOARD_MIX_MODULE'],
  })

  const hasReportMixModuleMlMc = validatePermission({
    user,
    moduleType: 'REPORT_MIX_MODULE',
    permission: ['REPORT_MONTHLY_PLAN_MANAGE_CLASS'],
  })

  const hasReportMixModuleExpenseMc = validatePermission({
    user,
    moduleType: 'REPORT_MIX_MODULE',
    permission: ['REPORT_EXPENSE_MANAGE_CLASS'],
  })

  const hasReportExpense = validatePermission({
    user,
    moduleType: 'REPORT_EXPENSE',
    permission: [PERMISSION_REPORT_EXPENSE.REPORT_EXPENSE],
  })

  const hasDashboardExpense = validatePermission({
    user,
    moduleType: 'REPORT_EXPENSE',
    permission: [
      PERMISSION_REPORT_EXPENSE.DASHBOARD_ADMIN,
      PERMISSION_REPORT_EXPENSE.DASHBOARD_HF,
      PERMISSION_REPORT_EXPENSE.DASHBOARD_TSD,
      PERMISSION_REPORT_EXPENSE.DASHBOARD_BY_DEPARTMENT,
    ],
  })

  const hasReportInventory = validatePermission({
    user,
    moduleType: 'INVENTORY',
    permission: ['REPORT'],
  })

  const hasDashboardInventory = validatePermission({
    user,
    moduleType: 'INVENTORY',
    permission: ['DASHBOARD'],
  })

  const hasAuditLog = validatePermission({
    user,
    moduleType: 'AUDIT_LOG',
    permission: ['AUDIT_LOG'],
  })

  const hasNonAcademy = validatePermission({
    user,
    moduleType: 'NON_ACADEMY_PROFILE_SETTING',
    permission: ['MENU'],
  })

  if (!hasELearning) {
    filterMenu = filterMenu.filter((item) => item.name !== 'E-Learning')
  } else if (!hasELearningHeadAdmin) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Learning') {
        menuItem.list = item.list.filter(
          (subMenu) =>
            subMenu.name !== 'Learning path' && subMenu.name !== 'Collection',
        )
      }
      return menuItem
    })

    if (!hasELearningAdmin) {
      filterMenu = filterMenu.map((item) => {
        let menuItem = item
        if (item.name === 'E-Learning') {
          menuItem.list = item.list.filter(
            (subMenu) =>
              subMenu.name !== 'Module' && subMenu.name != 'Dashboard',
          )
        }
        return menuItem
      })
    }
  }

  if (!hasMasterData)
    filterMenu = filterMenu.filter((item) => item.name !== 'Master Data')

  if (
    !hasCourseSetting &&
    !hasReportCourseSetting &&
    !hasDashboardCourseSetting
  )
    filterMenu = filterMenu.filter((item) => item.name !== 'จัดการหลักสูตร')

  if (!hasMonthlyPlanView)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'แผนรายเดือน') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'จัดการแผนงาน',
        )
      }
      return menuItem
    })

  if (!hasManagePermission)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'สิทธิ์การใช้งาน',
        )
      }
      return menuItem
    })

  if (
    !hasMonthlyPlanView &&
    !hasApproveZone &&
    !hasReportDashboardMonthlyPlan
  ) {
    filterMenu = filterMenu.filter((item) => item.name !== 'แผนรายเดือน')
  }

  if (!hasETesting) {
    filterMenu = filterMenu.filter((item) => {
      const menuItem = item
      if (item.name === 'E-Testing') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'แบบทดสอบทั้งหมด',
        )
      }
      return menuItem
    })
  }

  if (!hasETestingExam) {
    filterMenu = filterMenu.filter((item) => {
      const menuItem = item
      if (item.name === 'E-Testing') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'ตรวจข้อสอบ',
        )
      }
      return menuItem
    })
  }

  if (!hasETesting && !hasETestingExam) {
    filterMenu = filterMenu.filter((item) => item.name !== 'E-Testing')
  }

  if (!hasEValuation) {
    filterMenu = filterMenu.filter((item) => item.name !== 'E-Evaluation')
  }

  if (!hasECertification) {
    filterMenu = filterMenu.filter((item) => item.name !== 'E-Certificate')
  }

  if (!hasApiToRcms) {
    filterMenu = filterMenu.filter((item) => item.name !== 'API to RCMS')
  }

  if (!hasAgentProfile && hasELearningHeadAdmin && hasELearningAdmin) {
    filterMenu = filterMenu.filter((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'ข้อมูล Agent',
        )
      }
      return menuItem
    })
  }

  if (!hasCSAgentProfile && hasELearningHeadAdmin && hasELearningAdmin) {
    filterMenu = filterMenu.filter((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'ข้อมูล CS Agent',
        )
      }
      return menuItem
    })
  }

  if (!hasProspectProfile && hasELearningHeadAdmin && hasELearningAdmin) {
    filterMenu = filterMenu.filter((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'ข้อมูล Prospect',
        )
      }
      return menuItem
    })
  }

  if (!hasCSUserProfile && hasELearningHeadAdmin && hasELearningAdmin) {
    filterMenu = filterMenu.filter((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'ข้อมูล CS User',
        )
      }
      return menuItem
    })
  }

  if (!hasReportManageProfileAgent) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงานข้อมูล Agent',
        )
      }
      return menuItem
    })
  }

  if (!hasReportManageProfileAgentForTo) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงานข้อมูล Agent for TO',
        )
      }
      return menuItem
    })
  }

  if (!hasReportManageProfileAgentForTrainer) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงานข้อมูล Agent for Trainer',
        )
      }
      return menuItem
    })
  }

  if (!hasReportManageProfileProspect) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงานข้อมูล Prospect',
        )
      }
      return menuItem
    })
  }

  if (!hasReportManageProfileStaff) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงานข้อมูลพนักงาน',
        )
      }
      return menuItem
    })
  }

  if (!hasDashboardManageProfile) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Dashboard',
        )
      }
      return menuItem
    })
  }

  if (!hasSyncAgnet) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'อัพเดทข้อมูล Agent',
        )
      }
      return menuItem
    })
  }

  if (!hasNonAcademy) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Manage') {
        menuItem.list = item.list.filter(
          (subMenu) => !subMenu.name.includes('ข้อมูล Non Academy'),
        )
      }
      return menuItem
    })
  }

  if (!hasManageClassOicMenu)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการคลาส') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'ผลสอบใบอนุญาตตัวแทน',
        )
      }
      return menuItem
    })

  if (!hasManageClassExamMenu)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการคลาส') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'ตรวจข้อสอบ',
        )
      }
      return menuItem
    })

  if (!hasManageClassAllMenu)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการคลาส') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'คลาสทั้งหมด',
        )
      }
      return menuItem
    })

  if (!hasReportETestingDashboard)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Testing') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Dashboard',
        )
      }
      return menuItem
    })

  if (!hasReportETestingByClass)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Testing') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Report Raw Data By Class',
        )
      }
      return menuItem
    })

  if (!hasReportETestingByLearner)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Testing') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Report Raw Data By Learner',
        )
      }
      return menuItem
    })

  if (!hasReportManageClassByClass)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการคลาส') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงาน Export by Class',
        )
      }
      return menuItem
    })

  if (!hasReportManageClassByLearner)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการคลาส') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงาน Export by Learner',
        )
      }
      return menuItem
    })

  if (!hasReportManageClassWorkloadTo)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการคลาส') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงาน Workload TO',
        )
      }
      return menuItem
    })

  if (!hasReportManageClassHF)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการคลาส') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'รายงาน For HF',
        )
      }
      return menuItem
    })

  if (!hasManageClassMenu)
    filterMenu = filterMenu.filter((item) => item.name !== 'จัดการคลาส')

  if (!hasRoomManagement)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Room Management') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'การจองห้อง',
        )
      }
      return menuItem
    })

  if (!hasReportRoomManagement)
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Room Management') {
        menuItem.list = item.list.filter(
          (subMenu) => !['Dashboard', 'รายงาน'].includes(subMenu.name),
        )
      }
      return menuItem
    })

  if (!hasRoomManagement && !hasReportRoomManagement)
    filterMenu = filterMenu.filter((item) => item.name !== 'Room Management')

  if (!hasExamination) {
    filterMenu = filterMenu.filter((item) => item.name !== 'E-Examination')
  }

  if (hasExamination && !hasExaminationDc) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Examination') {
        menuItem.list = item.list.filter(
          (subMenu) => !['รายงานหักบัญชี'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasReportExamResult) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Examination') {
        menuItem.list = item.list.filter(
          (subMenu) => !['รายงานผลสอบ'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }
  if (!hasReportExamSchedule) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Examination') {
        menuItem.list = item.list.filter(
          (subMenu) => !['รายงานรอบสอบ'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasReportExamEnroll) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Examination') {
        menuItem.list = item.list.filter(
          (subMenu) => !['รายงานการรับสมัคร'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasDashboardExam) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Examination') {
        menuItem.list = item.list.filter(
          (subMenu) => !['Dashboard'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  const hasMasterDataView = validatePermission({
    user,
    moduleType: 'MASTER_DATA',
    permission: ['VIEW'],
  })
  if (!hasMasterDataView) {
    filterMenu = filterMenu.filter(
      (item) => !['Manage Content', 'Report'].includes(item.name),
    )
  }

  const hasInventory = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })

  const hasInventoryVM = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.VM],
  })

  if (!hasInventory) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Inventory') {
        menuItem.list = item.list.filter(
          (subMenu) =>
            !['คลังทรัพย์สิน', 'ทรัพย์สิน', 'โอนย้าย'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasInventoryVM) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Inventory') {
        menuItem.list = item.list.filter(
          (subMenu) => !['Vendor Management'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasEConfig) {
    filterMenu = filterMenu.filter((item) => item.name !== 'Configuration')
  }

  if (!hasLearningPoint) {
    filterMenu = filterMenu.filter(
      (item) => item.name !== 'Learning Point Management',
    )
  } else {
    if (!hasLearningPointCatalog) {
      filterMenu = filterMenu.map((item) => {
        let menuItem = item
        if (item.name === 'Learning Point Management') {
          menuItem.list = item.list.filter(
            (subMenu) => subMenu.name !== 'Catalog Management',
          )
        }
        return menuItem
      })
    }

    if (!hasLearningPointSettingPoint) {
      filterMenu = filterMenu.map((item) => {
        let menuItem = item
        if (item.name === 'Learning Point Management') {
          menuItem.list = item.list.filter(
            (subMenu) => subMenu.name !== 'ตั้งค่าคะแนน',
          )
        }
        return menuItem
      })
    }
  }

  if (!hasCourseSetting) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการหลักสูตร') {
        menuItem.list = item.list.filter(
          (subMenu) => !['หลักสูตร'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasReportCourseSetting) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการหลักสูตร') {
        menuItem.list = item.list.filter(
          (subMenu) => !['รายงาน'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasDashboardCourseSetting) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'จัดการหลักสูตร') {
        menuItem.list = item.list.filter(
          (subMenu) => !['ภาพรวม'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasBudgetManagement) {
    filterMenu = filterMenu.filter((item) => item.name !== 'Expense')
  }

  if (!hasEContent) {
    filterMenu = filterMenu.filter((item) => item.name !== 'E-Content')
  }

  if (
    !hasReportDashboardMonthlyPlan &&
    !hasApproveZone &&
    !hasMonthlyPlanView
  ) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'แผนรายเดือน') {
        menuItem.list = item.list.filter(
          (subMenu) =>
            ![
              'Class Dashboard',
              'Workload Dashboard',
              'รายงาน Workload',
            ].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasReportMixModule) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Mix Module Report') {
        menuItem.list = item.list.filter(
          (subMenu) => !['ผลการเรียนรายบุคคล'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasReportMixModuleDashboard) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Mix Module Report') {
        menuItem.list = item.list.filter(
          (subMenu) => !['Dashboard'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasReportMixModuleMlMc) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Mix Module Report') {
        menuItem.list = item.list.filter(
          (subMenu) =>
            !['RAW Monthly Plan + Manage Class'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasReportMixModuleExpenseMc) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Mix Module Report') {
        menuItem.list = item.list.filter(
          (subMenu) => !['RAW Expense + Manage Class'].includes(subMenu.name),
        )
      }
      return menuItem
    })
  }

  if (!hasEvaDashboard) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Evaluation') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Dashboard',
        )
      }
      return menuItem
    })
  }

  if (!hasReportEValuationByCourse) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Evaluation') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Report Raw Data By Course',
        )
      }
      return menuItem
    })
  }

  if (!hasReportEValuationByTrainer) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'E-Evaluation') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Report Raw Data By Trainer',
        )
      }
      return menuItem
    })
  }

  if (!hasReportExpense) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Expense') {
        menuItem.list = item.list.filter((subMenu) => subMenu.name !== 'รายงาน')
      }
      return menuItem
    })
  }

  if (!hasDashboardExpense) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Expense') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Dashboard',
        )
      }
      return menuItem
    })
  }
  if (!hasDashboardInventory) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Inventory') {
        menuItem.list = item.list.filter(
          (subMenu) => subMenu.name !== 'Dashboard',
        )
      }
      return menuItem
    })
  }
  if (!hasReportInventory) {
    filterMenu = filterMenu.map((item) => {
      let menuItem = item
      if (item.name === 'Inventory') {
        menuItem.list = item.list.filter(
          (subMenu) => !subMenu.name.includes('Report'),
        )
      }
      return menuItem
    })
  }

  if (!hasAuditLog) {
    filterMenu = filterMenu.filter((item) => item.name !== 'Audit Log')
  }

  return filterMenu
}
