import React, { useEffect, useState, useContext } from 'react'
import Card from '@mui/material/Card'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import useTheme from '@mui/system/useTheme'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocalStorage } from '@rehooks/local-storage'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import Breadcrumbs from '../../components/Breadcrumbs'
import FilterDrawer from './FilterDrawer'
import _ from 'lodash'

import {
  staffProfile,
  filterStaffProfile,
  downloadStaffProfile,
  filterNonAcademyProfile,
} from '../../utils/apiPath'
import { callAPI, handleDownload, validatePermission } from '../../utils/lib'
import { handleFilterTotal } from '../../redux/slices/table/events'

export const onFetchStaffSuccess = (
  data,
  setIsPageLoading,
  setDataList,
  setAllCount,
) => {
  setIsPageLoading(false)
  let resultFilter = _.get(data, 'result', [])

  if (resultFilter.length) {
    resultFilter.map((item) => {
      item.station = _.get(item, 'area[0].station.station', '')
      item.department = _.get(item, 'area[0].department.department', '')
      item.group = _.get(item, 'group.groupName', '')
      item.actionStaff = _.get(item, 'status', '')
      item.reportTo =
        _.get(item, 'reportTo.firstNameTH', '') +
        ' ' +
        _.get(item, 'reportTo.lastNameTH', '')
      item.jobTitle = _.get(item, 'jobTitles.jobTitle', '')
    })
  }
  setDataList(resultFilter)
  setAllCount(_.get(data, 'totalCount', '0'))
}

export const onDelete = async (
  row,
  dialog,
  setDialog,
  setAllCount,
  setIsLoading,
  fetchDataList,
  allCount,
) => {
  setDialog({
    ...dialog,
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    variant: 'delete',
    open: true,
    onConfirmClick: () =>
      handleDelete(
        row,
        dialog,
        setDialog,
        setAllCount,
        setIsLoading,
        fetchDataList,
        allCount,
      ),
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}

export const handleDelete = async (
  row,
  dialog,
  setDialog,
  setAllCount,
  setIsLoading,
  fetchDataList,
  allCount,
) => {
  const path = staffProfile + `/${row.uuid}`
  setIsLoading(true)
  callAPI({
    url: path,
    method: 'delete',
    onSuccess: () => {
      setAllCount(allCount - 1)
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      })
      fetchDataList()
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}
const StaffProfileList = ({ NonAcademy }) => {
  const url = !NonAcademy ? 'staff-profile' : 'non-academy-profile'
  const theme = useTheme()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState(['ACTIVE'])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [user] = useLocalStorage('user')
  const [staffPosition, setStaffPosition] = useState('')
  const [textFilter, setTextFilter] = useState({
    agentCode: '',
    firstName: '',
    lastName: '',
    firstNameTH: '',
    lastNameTH: '',
    jobTitle: '',
    department: '',
    station: '',
    reportTo: '',
  })
  const [filterTotal, setFilterTotal] = useState(0)

  const getPermissionStaffProfile = validatePermission({
    user: user,
    moduleType: 'MANAGE_PROFILE',
    permission: ['CREATE'],
  })
  const getPermissionNonAcademyProfile = validatePermission({
    user: user,
    moduleType: 'NON_ACADEMY_PROFILE_SETTING',
    permission: ['EDIT'],
  })

  const defaultHeadCells = [
    {
      id: 'agentCode',
      disablePadding: false,
      label: 'รหัสพนักงาน',
      width: '100px',
    },
    {
      id: 'firstName',
      label: 'First Name',
      disablePadding: false,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      disablePadding: false,
    },
    {
      id: 'firstNameTH',
      label: 'ชื่อ',
      disablePadding: false,
    },
    {
      id: 'lastNameTH',
      label: 'นามสกุล',
      disablePadding: false,
    },
    {
      id: 'jobTitle',
      label: 'Job Title',
      disablePadding: false,
    },
    {
      id: 'department',
      label: 'Department',
      disablePadding: false,
    },
    {
      id: 'station',
      label: 'Station',
      disablePadding: false,
    },
    {
      id: 'reportTo',
      label: 'Report to',
      disablePadding: false,
    },
    {
      id: 'actionStaff',
      disablePadding: false,
      userId: _.get(user, 'uuid', ''),
      hideView: false,
      hideEdit: getPermissionStaffProfile,
      hideSortIcon: true,
      label: 'คำสั่ง',
    },
  ]

  const defaultNonAcademyHeadCells = [
    {
      id: 'agentCode',
      disablePadding: false,
      label: 'รหัสพนักงาน',
      width: '120px',
    },
    {
      id: 'firstNameTH',
      label: 'ชื่อ',
      disablePadding: false,
    },
    {
      id: 'lastNameTH',
      label: 'นามสกุล',
      disablePadding: false,
    },
    {
      id: 'tel',
      label: 'เบอร์โทรศัพท์',
      disablePadding: false,
      width: '120px',
    },
    {
      id: 'email',
      label: 'อีเมล',
      disablePadding: false,
    },
    {
      id: 'actionStaff',
      disablePadding: false,
      userId: _.get(user, 'uuid', ''),
      hideView: false,
      hideEdit: getPermissionNonAcademyProfile,
      hideSortIcon: true,
      label: 'คำสั่ง',
    },
  ]

  const setStatusByPermission = (status) => {
    if (getPermissionStaffProfile)
      status.find((e) => e === 'INACTIVE') === undefined &&
        status.push('INACTIVE')
  }

  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog

  useEffect(() => {
    setStatusByPermission(status)
  }, [])

  useEffect(() => {
    setStaffPosition('')
    setIsPageLoading(true)
    fetchDataList(
      'fetch',
      status,
      limit,
      order,
      page,
      sort,
      searchText,
      textFilter,
    )
  }, [limit, order, sort, page])

  const onView = (row, history) => {
    history.push(`/manage/${url}/` + row.uuid)
  }
  const onEdit = (row, history) => {
    history.push(`/manage/${url}/edit/` + row.uuid)
  }

  const onDownload = async (selected) => {
    const body = {
      title: '',
      order: order.toUpperCase(),
      sort: sort === '' ? 'updatedAt' : sort,
      status: [],
      type: 'CUSTOM',
      list: selected,
      staffType: NonAcademy ? 'NON_ACADEMY' : 'STAFF',
    }
    setIsLoading(true)
    await handleDownload({
      url: downloadStaffProfile,
      body: body,
      fileName: `รายการข้อมูลพนักงาน.xlsx`,
    })
    setIsLoading(false)
  }

  const headCells = getPermissionStaffProfile
    ? defaultHeadCells
    : defaultHeadCells.filter((item) => item.id !== 'reportTo')

  const headNonAcademyCells = defaultNonAcademyHeadCells

  const breadcrumbList = !NonAcademy
    ? [
        { title: 'Manage', link: '/', pointer: false },
        { title: 'ข้อมูลพนักงาน', link: '/', pointer: false },
      ]
    : [
        { title: 'Manage', link: '/', pointer: false },
        { title: 'ข้อมูล Non Academy', link: '/', pointer: false },
      ]

  const handleQuickSearch = async (text) => {
    setTablePage(0)
    const filterText = {
      agentCode: '',
      firstName: '',
      lastName: '',
      firstNameTH: '',
      lastNameTH: '',
      jobTitle: '',
      department: '',
      station: '',
      reportTo: '',
    }
    setTextFilter(filterText)
    const statusList = ['ACTIVE', 'INACTIVE']
    fetchDataList('search', statusList, limit, order, 1, sort, text, filterText)
  }

  const onFilterClick = async (filter) => {
    setSearchText('')
    setText('')
    const statusList = []
    if (filter.statusChecked) {
      if (filter.status.activeChecked) statusList.push('ACTIVE')
      if (filter.status.deletedChecked) statusList.push('INACTIVE')
    } else {
      statusList.push('ACTIVE')
      setStatusByPermission(statusList)
    }
    const filterText = {
      agentCode: filter.agentCodeChecked ? filter.agentCode : '',
      firstNameTH: filter.firstNameTHChecked ? filter.firstNameTH : '',
      lastNameTH: filter.lastNameTHChecked ? filter.lastNameTH : '',
      firstName: filter.firstNameChecked ? filter.firstName : '',
      lastName: filter.lastNameChecked ? filter.lastName : '',
      jobTitle: filter.jobTitleChecked ? filter.jobTitle : '',
      department: filter.departmentChecked ? filter.department : '',
      station: filter.stationChecked ? filter.station : '',
      reportTo: filter.reportToChecked ? filter.reportTo : '',
      tel: filter.telChecked ? filter.tel : '',
      email: filter.emailChecked ? filter.email : '',
      createdBy: filter.createdByChecked ? filter.createdBy : '',
      updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
    }

    setStatus(statusList)
    setTextFilter(filterText)
    setSearchText(filterText.firstName)
    setTablePage(0)
    fetchDataList(
      'filter',
      statusList,
      limit,
      order,
      1,
      sort,
      filterText.firstName,
      filterText,
    )
    setFilterTotal(handleFilterTotal(filter))
  }

  const fetchDataList = async (
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    search,
    filter,
  ) => {
    setIsPageLoading(true)
    const sortBy = sort === '' ? 'updatedAt' : sort
    const realPage = page <= 0 ? 1 : +page
    const body = {
      firstName: method === 'filter' ? filter?.firstName : search,
      lastName: _.get(filter, 'lastName', ''),
      email: _.get(filter, 'email', ''),
      username: '',
      position: '',
      agentCode: _.get(filter, 'agentCode', ''),
      firstNameTH: _.get(filter, 'firstNameTH', ''),
      lastNameTH: _.get(filter, 'lastNameTH', ''),
      jobTitle: _.get(filter, 'jobTitle', ''),
      department: _.get(filter, 'department', ''),
      station: _.get(filter, 'station', ''),
      reportTo: _.get(filter, 'reportTo', ''),
      tel: _.get(filter, 'tel', ''),
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      status: statusList,
    }
    callAPI({
      method: 'post',
      url: !NonAcademy ? filterStaffProfile : filterNonAcademyProfile,
      body: body,
      onSuccess: (data) => {
        onFetchStaffSuccess(data, setIsPageLoading, setDataList, setAllCount)
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            ข้อมูล
            {!NonAcademy ? 'พนักงาน (Staff Profile)' : ' Non Academy'}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Link data-testid={`btn-add-${url}`} to={`/manage/${url}/form`}>
          {(getPermissionStaffProfile || getPermissionNonAcademyProfile) && (
            <Button
              sx={{
                ml: 3,
                [theme.breakpoints.up('md')]: {
                  position: 'absolute',
                  mt: -10,
                  mr: 3,
                },
                right: 0,
              }}
              startIcon={<AddIcon />}
              variant="contained"
            >
              เพิ่มข้อมูลพนักงาน
            </Button>
          )}
        </Link>
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={dataList}
            headCells={NonAcademy ? headNonAcademyCells : headCells}
            onView={(row) => onView(row, history)}
            onEdit={(row) => onEdit(row, history)}
            isLoading={isPageLoading}
            onDelete={(row) =>
              onDelete(
                row,
                dialog,
                setDialog,
                setAllCount,
                setIsLoading,
                fetchDataList(
                  'fetch',
                  status,
                  limit,
                  order,
                  page,
                  sort,
                  searchText,
                  staffPosition,
                ),
                allCount,
              )
            }
            placeholder={
              !NonAcademy ? 'ค้นหาชื่อพนักงาน' : 'ค้นหาชื่อ Non Acdemy'
            }
            searchKey={'staffProfile'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={handleQuickSearch}
            onDownload={onDownload}
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading && isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={onFilterClick}
        isPermission={getPermissionStaffProfile}
        NonAcademy={NonAcademy}
      />
    </Box>
  )
}
export default StaffProfileList
